<template>
  <v-dialog persistent max-width="700" :value="true">
    <v-card>
      <v-toolbar :color="refundStatusColor" dark>
        <v-toolbar-title v-if="submittedRefund.status === 'Failed'">
          The refund has failed. Please try again.
        </v-toolbar-title>
        <v-toolbar-title v-else-if="submittedRefund.status === 'Completed'">
          The refund has been completed successfully.
        </v-toolbar-title>
        <v-toolbar-title v-else>
          {{
            responseReceived && orderIsInterac
              ? "Action Required!"
              : awaitingResponse
              ? "Please wait..."
              : `Refund ${submittedRefund.status}`
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-progress-linear
        v-if="submittedRefund.status === 'Pending'"
        color="warning"
        height="10"
        indeterminate
      ></v-progress-linear>
      <v-card-title
        v-if="
          submittedRefund.status === 'Pending' && responseReceived && orderIsInterac
        "
      >
        <h5>Important: Please use the pinpad to continue refund.</h5>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row justify="center">
          <v-col v-if="submittedRefund.status !== 'Pending'">
            <p v-if="submittedRefund.status === 'Failed'">
              We were not able to process the refund
              <span v-if="submittedRefund.failedReason">
                due to the following reason: {{ submittedRefund.failedReason }}
              </span>
              <span v-else>.</span>
            </p>
            <p v-else-if="submittedRefund.status === 'Completed'">
              Press "Done" to return to the orders page.
            </p>
          </v-col>
          <v-col v-else-if="responseReceived && orderIsInterac">
            <p>
              Since this is an Interac transaction, the card needs to be used on the
              pinpad to process the refund.
            </p>
            <p v-if="submittedRefund.status === 'Pending'">
              Waiting for finalization through pinpad...
            </p>
            <v-btn
              v-if="submittedRefund.status === 'Pending'"
              color="primary"
              :disabled="cancelRequested"
              @click="requestCancel"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col v-else-if="responseReceived">
            <p>The refund has been submitted and will be processed shortly.</p>
            <p>
              To ensure the refund goes through, please stay on this page until it
              has been processed
            </p>
            <p>Processing...</p>
          </v-col>
          <v-col v-else-if="awaitingResponse">
            <p>Submitting refund...</p>
            <p>Do NOT close this window.</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end pb-2">
        <v-btn
          v-if="submittedRefund.status === 'Failed'"
          color="secondary"
          @click="$emit('back')"
        >
          Back
        </v-btn>
        <v-btn
          v-else-if="submittedRefund.status === 'Completed'"
          color="secondary"
          @click="$emit('close')"
        >
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "refund-process",
  props: {
    awaitingResponse: {
      type: Boolean,
      required: true,
    },
    responseReceived: {
      type: Boolean,
      required: true,
    },
    refundId: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submittedRefund: {
        status: "Pending",
      },
      cancelRequested: false,
      refundInterval: null,
    }
  },
  computed: {
    ...mapState(["refunds"]),
    refundStatusColor() {
      switch (this.submittedRefund.status) {
        case "Pending":
          return "orange"
        case "Completed":
          return "green"
        case "Failed":
          return "red"
      }
      return "grey"
    },
    orderIsInterac() {
      return this.order.channelData?.cardType === "interac"
    },
  },
  created() {
    this.refundInterval = setInterval(() => {
      if (this.submittedRefund.status === "Pending") {
        this.fetchRefunds().then(() => {
          const thisRefund = this.refunds.find(refund => refund.id === this.refundId)
          if (thisRefund) {
            this.submittedRefund = thisRefund
          }
        })
      } else {
        clearInterval(this.refundInterval)
      }
    }, 2000)
  },
  beforeDestroy() {
    clearInterval(this.refundInterval)
  },
  methods: {
    ...mapActions(["fetchRefunds"]),
    close() {
      this.$emit("close")
    },
    requestCancel() {
      this.cancelRequested = true
      this.$emit("cancel")
    },
  },
}
</script>
