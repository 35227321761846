<template>
  <orders-page
    title="Past Orders"
    :show-date-filter="true"
    :include-completed-orders="true"
  />
</template>

<script>
import OrdersPage from "./index.vue"
export default {
  name: "past-orders",
  components: {
    OrdersPage,
  },
}
</script>
