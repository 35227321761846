<template>
  <div>
    <page-component
      v-model="selectedOrders"
      :pageTitle="title"
      :headers="headers"
      :items="filteredOrders"
      :loadingData="ordersLoading"
      :show-select="currentUserRole !== 'Rider'"
    >
      <template v-slot:titleSuffix>
        <v-switch
          v-model="soundIsOn"
          :append-icon="soundIsOn ? 'mdi-volume-high' : 'mdi-volume-off'"
          color="primary"
          class="d-inline-flex ml-3 my-0"
        />
      </template>
      <template v-slot:subheading>
        <v-alert
          v-if="$route.query.onboardingDone === 'true'"
          type="success"
          icon="mdi-check-circle"
          max-width="600"
          class="mt-0 mb-2 py-3"
          dense
        >
          Success! You have completed the onboarding process.
        </v-alert>
        <v-alert
          v-else-if="!soundIsOn"
          type="warning"
          icon="mdi-volume-off"
          max-width="600"
          class="mt-0 mb-2"
          dense
        >
          <v-row align="center">
            <v-col class="grow">Sound notifications are OFF.</v-col>
            <v-col class="shrink">
              <v-btn
                @click="
                  setSoundOn(true)
                  setNotification({ message: 'Sound ON', icon: 'volume-high' })
                "
              >
                Turn on
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <orders-summary
          v-if="!scheduledOnly"
          :orders="
            todayOnly ? orders.filter(o => o.status !== 'Pending') : filteredOrders
          "
          :today-only="todayOnly"
        />
      </template>
      <template
        v-if="selectedOrders.length && currentUserRole !== 'Rider'"
        v-slot:buttons
      >
        <v-menu offset-y style="z-index: 200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="fixed-btn" v-bind="attrs" v-on="on">
              Bulk Operations
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="markCompleteBulk">
              <v-list-item-title>Mark as Completed</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-if="showDateFilter" v-slot:filters[0]>
        <custom-date-range-filter
          dense
          :initial-dates="dates"
          @change="datesRangeUpdated"
        />
      </template>
      <template v-if="restaurants.length > 1" v-slot:filters[1]>
        <custom-select
          v-model="selectedRestaurantIds"
          :items="restaurants"
          label="Restaurants"
          item-value="id"
          item-text="name"
          multiple
          dense
        />
      </template>
      <template v-if="!scheduledOnly" v-slot:filters[2]>
        <custom-select
          v-model="orderStatusFilter"
          :items="orderStatusOptions"
          label="Order Status"
          multiple
          dense
        />
      </template>
      <template v-slot:filters[3]>
        <custom-select
          v-model="orderSourceFilter"
          :items="orderSourceOptions"
          label="Order Source"
          multiple
          dense
        />
      </template>
      <template v-slot:[`item.source`]="{ item }">
        <order-detail-source :order="item" />
      </template>
      <template v-slot:[`item.orderDate`]="{ item }">
        <div class="pt-2">
          {{ getOrderDate(item.orderDate) }}
          <br />
          <span style="color: var(--v-lighttext-base)">
            {{ getOrderTime(item.orderDate) }}
          </span>
          <order-timing-chart v-if="item.timestampData" :order="item" />
        </div>
        <v-alert
          v-if="item.status === 'Cancelled'"
          colored-border
          border="top"
          color="red"
          elevation="1"
          class="mt-2 py-2 pl-2"
          dense
          style="min-width: 135px"
        >
          <v-icon color="red" class="mr-1" small>mdi-alert</v-icon>
          <small><strong style="color: red">ORDER CANCELLED</strong></small>
        </v-alert>
        <v-alert
          v-else-if="isOrderScheduled(item)"
          colored-border
          border="top"
          color="orange"
          elevation="1"
          class="mt-2 py-2 pl-2"
          dense
          style="min-width: 135px"
        >
          <v-icon color="orange" class="mr-1" small>mdi-clock</v-icon>
          <small>
            Scheduled for pickup
            <span
              v-if="getOrderDate(item.pickupDate) !== getOrderDate(item.orderDate)"
            >
              on {{ getOrderDate(item.pickupDate) }}
            </span>
            at {{ getOrderTime(item.pickupDate) }}
          </small>
        </v-alert>
      </template>
      <template v-slot:[`item.customerInfo`]="{ item }">
        <order-detail-customer :order="item" />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-select
          :value="getOrderStatus(item)"
          :items="getStatusOptions(getOrderStatus(item))"
          :disabled="
            getOrderStatus(item) === 'Refunded' ||
            getOrderStatus(item) === 'Cancelled'
          "
          :color="getStatusColor(getOrderStatus(item))"
          outlined
          hide-details
          :class="getStatusColor(getOrderStatus(item)) + '-select'"
          dense
          @input="changeStatus(item, $event)"
        />
        <p
          v-if="isRestaurantStatusView && item.status !== getOrderStatus(item)"
          class="ma-0"
        >
          <small class="text--disabled ml-3">
            Main order status: {{ item.status }}
          </small>
        </p>
        <p
          v-if="
            item.receiptData && item.receiptData.printed && receiptPrinterEnabled
          "
          class="ma-0"
        >
          <v-icon small class="mx-1">mdi-printer-pos</v-icon>
          <small class="text--disabled">Receipt Requested</small>
        </p>
      </template>
      <template v-slot:[`item.restaurantIds`]="{ item }">
        {{
          item.restaurantIds
            ? item.restaurantIds
                .map(restaurantId => getRestaurantName(restaurantId))
                .join(", ")
            : "N/A"
        }}
      </template>
      <template v-slot:[`item.orderInfo`]="{ item }">
        <v-expansion-panels class="my-2" style="min-width: 300px" multiple>
          <order-expandable-panel :order="item">
            <v-expansion-panel-content
              v-if="item.deliveryNote || item.orderNote"
              class="pt-3 pb-2"
              style="white-space: pre-line"
            >
              <p class="text--disabled mb-2">NOTES:</p>
              {{ item.deliveryNote }}
              {{ item.orderNote.replace("NOTE: ", "") }}
            </v-expansion-panel-content>
            <v-expansion-panel-content
              v-if="currentUserRole === 'Admin'"
              class="border-top pt-4 pb-2"
              elevation="5"
              color="light-background"
              style="border-color: #ddd !important"
            >
              <v-row>
                <v-col
                  v-if="getOrderType(item) !== 'Delivery'"
                  cols="3"
                  class="pb-0 mb-n1 green--text text-uppercase"
                >
                  <small>Subtotal</small>
                </v-col>
                <v-col cols="3" class="pb-0 mb-n1 green--text text-uppercase">
                  <small>Tips</small>
                </v-col>
                <v-col cols="3" class="pb-0 mb-n1 green--text text-uppercase">
                  <small>Service</small>
                </v-col>
                <v-col
                  v-if="getOrderType(item) === 'Delivery'"
                  cols="3"
                  class="pb-0 mb-n1 green--text text-uppercase"
                >
                  <small>Delivery</small>
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0 mb-n1 pr-0 text-uppercase d-flex justify-end"
                  :class="
                    item.channelData && item.channelData.amountDue > 0
                      ? 'red--text'
                      : 'green--text'
                  "
                >
                  <small
                    v-if="item.channelData && item.channelData.amountDue > 0"
                    style="color: red"
                  >
                    <strong>UNPAID</strong>
                    <v-icon color="red" dark class="mt-n1">mdi-cash-remove</v-icon>
                  </small>
                  <small v-else>
                    paid
                    <v-icon color="green">mdi-cash-check</v-icon>
                  </small>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="getOrderType(item) !== 'Delivery'" cols="3">
                  {{ (parseFloat(item.priceData.subTotal || 0) / 100) | currency }}
                </v-col>
                <v-col cols="3">
                  {{ (parseFloat(item.priceData.tip || 0) / 100) | currency }}
                  <span v-if="item.priceData.driverTip">
                    + {{ (parseFloat(item.priceData.driverTip) / 100) | currency }}
                  </span>
                </v-col>
                <v-col cols="3">
                  {{
                    (parseFloat(item.priceData.serviceCharge || 0) / 100) | currency
                  }}
                </v-col>
                <v-col v-if="getOrderType(item) === 'Delivery'" cols="3">
                  {{
                    (parseFloat(item.priceData.deliveryCost || 0) / 100) | currency
                  }}
                </v-col>
                <v-col cols="3" class="text-right d-flex justify-end">
                  {{ (parseFloat(item.priceData.total || 0) / 100) | currency }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </order-expandable-panel>
        </v-expansion-panels>
        <v-alert
          v-if="item.channelData && item.channelData.amountDue > 0"
          colored-border
          border="top"
          color="red"
          elevation="1"
          class="mt-2 pb-3 pt-4 pl-6"
          dense
          style="min-width: 135px"
        >
          <v-icon color="red" class="ml=n2">mdi-cash-remove</v-icon>
          <small>
            Order is unpaid!
            <br />
            {{ (item.channelData.amountDue / 100) | currency }} needs to be collected
            from customer.
          </small>
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="receiptPrinterEnabled"
          type="print"
          @click="printReceiptToPrinterManual(item)"
        />
        <div v-if="currentUserRole === 'Admin'">
          <nxg-action-btn
            type="refund"
            :disabled="
              item.priceData.total === 0 ||
              item.status === 'Refunded' ||
              !['kiosk', 'web', 'mobile'].includes(item.channelData.appType)
            "
            :title="
              item.priceData.total === 0
                ? '$0 order cannot be refunded'
                : item.status === 'Refunded'
                ? 'Order has already been refunded'
                : 'Order was placed through a third-party app'
            "
            @click="refundOrder(item)"
          />
          <nxg-action-btn
            type="complete"
            :loading="completingOrder(item.id)"
            @click="markComplete(item)"
          />
        </div>
        <nxg-action-btn
          v-if="
            currentUserRole === 'Rider' &&
            getOrderStatus(item) === 'Ready For Pickup'
          "
          type="complete"
          @click="changeStatus(item, 'Completed')"
        />
      </template>
    </page-component>
    <refund v-if="$route.params.form && currentUserRole === 'Admin'" />
  </div>
</template>

<script>
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import OrdersSummary from "./OrdersSummary.vue"
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import Refund from "./Refund.vue"
import PrintReceiptToPrinterManual from "@/mixins/printReceipt.js"
import OrderDetailSource from "./fields/OrderDetailSource.vue"
import OrderDetailCustomer from "./fields/OrderDetailCustomer.vue"
import OrderTimingChart from "./fields/OrderTimingChart.vue"
import OrdersMixins from "./mixins"
import OrderExpandablePanel from "@/AuthenticatedContent/shared/OrderExpandablePanel"
import CustomDateRangeFilter from "@/AuthenticatedContent/shared/forms/datetime/CustomDateRangeFilter.vue"
import CustomSelect from "@/AuthenticatedContent/shared/CustomSelect.vue"
import moment from "moment"

export default {
  name: "orders",
  components: {
    PageComponent,
    OrdersSummary,
    Refund,
    OrderDetailSource,
    OrderDetailCustomer,
    OrderTimingChart,
    OrderExpandablePanel,
    CustomDateRangeFilter,
    CustomSelect,
  },
  mixins: [PrintReceiptToPrinterManual, OrdersMixins],
  props: {
    title: {
      type: String,
      default: "Orders",
    },
    showDateFilter: {
      type: Boolean,
      default: true,
    },
    includeCompletedOrders: {
      type: Boolean,
      default: true,
    },
    todayOnly: {
      type: Boolean,
      default: false,
    },
    scheduledOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ordersLoading: false,
      orderStatusFilter: this.includeCompletedOrders
        ? ["open", "completed"]
        : ["open"],
      orderSourceFilter: [],
      selectedRestaurantIds: [],
      selectedOrders: [],
      dates: this.todayOnly
        ? [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        : [
            moment().subtract(7, "days").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ],
      ordersBeingCompleted: [],
    }
  },
  computed: {
    ...mapState([
      "currentUserRole",
      "orders",
      "ordersToPrint",
      "refunds",
      "allOrders",
      "allOpenOrders",
      "customers",
      "restaurants",
      "settings",
      "soundOn",
    ]),
    ...mapGetters(["getRestaurantName", "receiptPrinterEnabled"]),
    soundIsOn: {
      get() {
        return this.soundOn
      },
      set(value) {
        this.setSoundOn(value)
      },
    },
    headers() {
      const headers = [
        { text: "Source", value: "source", width: "100" },
        { text: "Time", value: "orderDate", width: "120" },
        { text: "Status", value: "status", width: "15%" },
        { text: "Customer", value: "customerInfo", width: "205" },
        { text: "Order Info", value: "orderInfo", width: "400" },
        { text: "Actions", value: "actions", align: "center", width: "180" },
      ]
      if (!["Admin", "Rider"].includes(this.currentUserRole)) {
        headers.splice(5, 1)
      }
      return headers
    },
    statusOptions() {
      return [
        {
          text: "Pending",
          value: "Pending",
          disabled: true,
        },
        {
          text: "Payment Accepted",
          value: "Payment Accepted",
          disabled: true,
        },
        {
          text: "Accepted",
          value: "Accepted",
          disabled: true,
        },
        {
          text: "In Progress",
          value: "In Progress",
          disabled: this.currentUserRole === "Rider",
        },
        {
          text: "Ready For Pickup",
          value: "Ready For Pickup",
          disabled: this.currentUserRole === "Rider",
        },
        {
          text: "Completed",
          value: "Completed",
          disabled:
            this.settings?.ownDeliveryEnabled &&
            !["Rider", "Admin"].includes(this.currentUserRole),
        },
        {
          text: "Refunded",
          value: "Refunded",
          disabled: true,
        },
        {
          text: "Cancelled",
          value: "Cancelled",
          disabled: true,
          color: "red",
        },
      ]
    },
    ordersStateName() {
      return this.todayOnly
        ? "orders"
        : this.scheduledOnly
        ? "allOpenOrders"
        : "allOrders"
    },
    filteredOrders() {
      let filteredOrders = this[this.ordersStateName]
      if (this.scheduledOnly) {
        filteredOrders = this[this.ordersStateName].filter(order => {
          return this.isOrderScheduled(order)
        })
      }

      filteredOrders = filteredOrders.filter(
        order => !["Failed"].includes(order.status)
      )
      if (this.dates?.length) {
        filteredOrders = filteredOrders.filter(order => {
          const orderDate = order.orderDate.toDate()
          return (
            moment(orderDate).isSameOrAfter(this.dates[0]) &&
            moment(orderDate).isSameOrBefore(moment(this.dates[1]).endOf("day"))
          )
        })
      }
      if (this.restaurants.length > 1) {
        if (this.selectedRestaurantIds?.length) {
          filteredOrders = filteredOrders.filter(order => {
            return (
              !order.restaurants?.length ||
              order.restaurants.some(restaurant => {
                return this.selectedRestaurantIds.includes(restaurant.id)
              })
            )
          })
        } else {
          return []
        }
      }

      if (this.currentUserRole === "Rider") {
        filteredOrders = filteredOrders.filter(order => {
          return ["kiosk", "web", "mobile"].includes(order.channelData.appType)
        })
      } else if (this.currentUserRole !== "Admin") {
        filteredOrders = filteredOrders.filter(order => {
          return order.restaurants?.some(restaurant =>
            this.restaurants.some(r => r.id === restaurant.id)
          )
        })
      }

      filteredOrders = filteredOrders.filter(order => {
        const conditions = []
        if (!this.orderSourceFilter.length || !this.orderStatusFilter) {
          conditions.push(false)
        } else {
          // status filter
          const includedStatuses = []
          if (this.orderStatusFilter.includes("pending")) {
            includedStatuses.push("Pending")
          }
          if (this.orderStatusFilter.includes("open")) {
            includedStatuses.push(
              "Payment Accepted",
              "Accepted",
              "In Progress",
              "Ready For Pickup"
            )
          }
          if (this.orderStatusFilter.includes("completed")) {
            includedStatuses.push("Completed", "Refunded", "Cancelled")
          }
          if (!this.isRestaurantStatusView) {
            conditions.push(includedStatuses.includes(order.status))
          } else {
            conditions.push(
              order.restaurants.some(restaurant =>
                this.restaurants.some(r => {
                  return (
                    r.id === restaurant.id &&
                    includedStatuses.includes(restaurant.status)
                  )
                })
              )
            )
          }
          // source filter
          if (!this.orderSourceFilter.includes("dineIn")) {
            conditions.push(
              order.channelData.appType !== "kiosk" ||
                order.channelData.orderType !== "dineIn"
            )
          }
          if (!this.orderSourceFilter.includes("takeOut")) {
            conditions.push(
              order.channelData.appType !== "kiosk" ||
                order.channelData.orderType !== "takeOut"
            )
          }
          for (const appType of this.appTypes) {
            if (!this.orderSourceFilter.includes(appType)) {
              conditions.push(order.channelData.appType !== appType)
            }
          }
        }
        return conditions.every(condition => condition)
      })
      filteredOrders = filteredOrders.map(order => {
        return {
          ...order,
          customerInfo: this.getOrderCustomerInfo(order),
        }
      })
      return filteredOrders.sort((a, b) => {
        return moment(b.orderDate?.toDate()).diff(a.orderDate?.toDate())
      })
    },
    appTypes() {
      let orders = this[this.ordersStateName]
      if (this.scheduledOnly) {
        orders = this[this.ordersStateName].filter(order => {
          return this.isOrderScheduled(order)
        })
      }

      return [
        ...new Set(
          orders.map(
            order => order.channelData.appType || order.channelData.orderType
          )
        ),
      ].filter(appType => appType && appType !== "kiosk")
    },
    orderStatusOptions() {
      return [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Completed",
          value: "completed",
        },
      ]
    },
    orderSourceOptions() {
      return [
        {
          text: "Kiosk Dine-In",
          value: "dineIn",
        },
        {
          text: "Kiosk Take-Out",
          value: "takeOut",
        },
      ].concat(
        this.currentUserRole === "Rider"
          ? [
              {
                text: "Web",
                value: "web",
              },
              {
                text: "Mobile",
                value: "mobile",
              },
            ]
          : this.appTypes.map(appType => {
              return {
                text: appType.charAt(0).toUpperCase() + appType.slice(1),
                value: appType,
              }
            })
      )
    },
    isRestaurantStatusView() {
      return (
        this.currentUserRole !== "Admin" &&
        this.settings.restaurantStatusChangeEnabled
      )
    },
  },
  watch: {
    todayOnly() {
      this.fetchAllOrdersIfNeeded()
    },
    scheduledOnly() {
      this.fetchAllOrdersIfNeeded()
    },
    // This watch function is necessary to ensure any newly added order sources are selected by default
    orderSourceOptions() {
      // First, find any order source options that were not in the filter previously
      const unselectedSourceFilters = this.orderSourceOptions
        .filter(option => !this.orderSourceFilter.includes(option.value))
        .map(option => option.value)
      // Now, select all the order source options except the unselected ones
      this.orderSourceFilter = this.orderSourceOptions
        .filter(option => !unselectedSourceFilters.includes(option))
        .map(option => option.value)
    },
    // ordersToPrint() {
    //   // print any new orders coming in
    //   if (this.ordersToPrint.length) {
    //     this.ordersToPrint.forEach(orderId => {
    //       const order = this.orders.find(order => order.id === orderId)
    //       if (order.status === "Accepted" || order.status === "Completed") {
    //         // alert("new order:" + order.id)
    //         console.log("new order:" + order, order)
    //         // this.printReceiptToPrinterManual(order)
    //         this.removeOrderFromPrint(orderId)
    //       }
    //     })
    //   }
    // },
    // orderStatuses() {
    //   // if the status of any order in the ordersToPrint array changes to Accepted or Completed, print it
    //   if (this.ordersToPrint.length) {
    //     this.ordersToPrint.forEach(orderId => {
    //       const order = this.orders.find(order => order.id === orderId)
    //       if (order.status === "Accepted" || order.status === "Completed") {
    //         // alert("new order:" + order.id)
    //         console.log("new orderrrrrr:" + order, order)
    //         // this.printReceiptToPrinterManual(order)
    //         this.removeOrderFromPrint(orderId)
    //       }
    //     })
    //   }
    // },
  },
  async mounted() {
    if (this.scheduledOnly) {
      this.ordersLoading = true
      this.fetchAllOpenOrders()
        .then(() => {
          this.ordersLoading = false
        })
        .finally(this.setDefaultFilters)
    } else if (!this.todayOnly) {
      this.ordersLoading = true
      let dateRange
      if (this.dates && this.dates.length === 2) {
        dateRange = {
          startDate: new Date(this.dates[0]),
          endDate: new Date(this.dates[1]),
        }
      }
      this.fetchAllOrders(dateRange)
        .then(() => {
          this.ordersLoading = false
        })
        .finally(this.setDefaultFilters)
    } else {
      this.setDefaultFilters()
    }
  },
  methods: {
    ...mapActions([
      "fetchAllOrders",
      "fetchAllOpenOrders",
      "saveOrdersAsCompleted",
      "updateOrder",
    ]),
    ...mapMutations(["removeOrderFromPrint", "setSoundOn", "setNotification"]),
    getOrderDate(date) {
      return moment(date.toDate()).format("MMM DD, YYYY")
    },
    getOrderTime(date) {
      return moment(date.toDate()).format("h:mm A")
    },
    getOrderCustomerInfo(order) {
      let customerInfo
      if (order.tableNumber) {
        customerInfo = order.tableNumber
      } else if (order.userId) {
        customerInfo = this.getCustomerName(order.userId)
      } else {
        customerInfo = "Customer"
      }
      return customerInfo
    },
    getOrderType(order) {
      if (order.channelData?.orderType) {
        return order.channelData.orderType
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, str => str.toUpperCase())
      } else {
        return "N/A"
      }
    },
    getOrderSource(order) {
      if (order.channelData?.appType) {
        return order.channelData.appType
      } else {
        return "N/A"
      }
    },
    isOrderScheduled(order) {
      if (!order.pickupDate || !order.orderDate) {
        return false
      }

      return (
        moment(order.pickupDate.toDate()).diff(
          moment(order.orderDate.toDate()),
          "minutes"
        ) > 20
      )
    },
    getCustomerName(userId) {
      if (userId === "Guest") return userId
      return this.customers?.find(customer => customer.uid == userId) != undefined
        ? this.customers.find(customer => customer.uid == userId).displayName
        : null
    },
    getStatusOptions(status) {
      const statuses = this.statusOptions
      if (status === "Pending") {
        const i = statuses.findIndex(o => o.value === "Accepted")
        statuses[i].disabled = undefined
      }
      return statuses
    },
    datesRangeUpdated(dates) {
      this.ordersLoading = true
      let dateRange
      if (dates && dates.length === 2) {
        dateRange = {
          startDate: new Date(dates[0]),
          endDate: new Date(dates[1]),
        }
      }
      this.fetchAllOrders(dateRange).then(() => {
        this.ordersLoading = false
        this.dates = dates
      })
    },
    getStatusColor(status) {
      switch (status) {
        case "Pending":
        case "Ready For Pickup":
          return "orange"
        case "Completed":
          return "green"
        case "Refunded":
          return "red"
        case "Cancelled":
          return "red"
        default:
          return "default"
      }
    },
    fetchAllOrdersIfNeeded() {
      if (this.scheduledOnly) {
        this.ordersLoading = true
        this.fetchAllOpenOrders().then(() => {
          this.ordersLoading = false
        })
      } else if (!this.todayOnly) {
        this.ordersLoading = true
        let dateRange
        if (this.dates && this.dates.length === 2) {
          dateRange = {
            startDate: new Date(this.dates[0]),
            endDate: new Date(this.dates[1]),
          }
        }
        this.fetchAllOrders(dateRange).then(() => {
          this.ordersLoading = false
        })
      }
    },
    getOrderStatus(item) {
      if (this.isRestaurantStatusView) {
        return item.restaurants?.find(orderRestaurant =>
          this.restaurants.some(restaurant => restaurant.id === orderRestaurant.id)
        )?.status
      } else {
        return item.status
      }
    },
    async changeStatus(item, newStatus) {
      if (newStatus === "Ready For Pickup") {
        if (
          this.receiptPrinterEnabled &&
          item.receiptData?.printed &&
          confirm(
            "Customer has requested a printed receipt. \n\n" +
              "Press OK to send to printer now and then attach to order. \n" +
              "Press Cancel if you do NOT want to print the receipt automatically."
          )
        ) {
          this.printReceiptToPrinterManual(item)
        }
      }

      let orderUpdates = { id: item.id }

      if (this.isRestaurantStatusView) {
        item.restaurants.forEach(orderRestaurant => {
          if (
            this.restaurants.find(restaurant => restaurant.id === orderRestaurant.id)
          ) {
            orderRestaurant.status = newStatus
          }
        })
        orderUpdates.restaurants = item.restaurants
        const allHaveSameStatus = item.restaurants.every(
          orderRestaurant => orderRestaurant.status === newStatus
        )
        if (allHaveSameStatus) {
          orderUpdates.status = newStatus
        }
      } else {
        orderUpdates.status = newStatus
      }
      await this.updateOrder({
        payload: orderUpdates,
        stateType: this.ordersStateName,
      })
    },
    refundOrder(order) {
      this.$router.push({ params: { form: "RefundForm", id: order.id } })
    },
    async setDefaultFilters() {
      this.orderSourceFilter = this.orderSourceOptions.map(option => option.value)
      this.selectedRestaurantIds = this.restaurants.map(restaurant => restaurant.id)
    },
    markCompleteBulk() {
      if (this.currentUserRole === "Rider") {
        return
      }
      if (
        confirm(
          "This will mark all selected orders as complete, as well as all associated Square orders. \n\n" +
            "Are you sure you want to continue?"
        )
      ) {
        const selectedOrderIds = this.selectedOrders
          .filter(
            order =>
              order.status !== "Completed" ||
              order.restaurants.findIndex(r => r.status !== "Completed") !== -1
          )
          .map(order => order.id)
        this.ordersBeingCompleted.push(...selectedOrderIds)
        this.markOrdersAsCompleted(selectedOrderIds)
          .then(response => {
            if (response?.data?.length) {
              this.saveOrdersAsCompleted(response.data)
            }
          })
          .catch(e => {
            console.error("Error with marking orders as complete", e)
          })
          .finally(() => {
            this.ordersBeingCompleted = this.ordersBeingCompleted.filter(
              id => !selectedOrderIds.includes(id)
            )
            this.selectedOrders = []
          })
      }
    },
    async markComplete(order) {
      if (this.currentUserRole === "Rider") {
        return
      }
      if (
        confirm(
          "This will mark this order as complete, as well as all associated Square orders. \n\n" +
            "Are you sure you want to continue?"
        )
      ) {
        this.ordersBeingCompleted.push(order.id)
        await this.markOrdersAsCompleted([order.id])
          .then(response => {
            if (response?.data?.length) {
              this.saveOrdersAsCompleted(response.data)
            }
            this.ordersBeingCompleted = this.ordersBeingCompleted.filter(
              id => id !== order.id
            )
            this.filteredOrders.find(o => o.id === order.id).status = "Completed"
          })
          .catch(e => {
            console.error("Error with marking order as complete", e)
            this.ordersBeingCompleted = this.ordersBeingCompleted.filter(
              id => id !== order.id
            )
          })
      }
    },
    completingOrder(orderId) {
      return this.ordersBeingCompleted.includes(orderId)
    },
    isKioskOrder(order) {
      return (
        this.customers?.find(customer => customer.uid == order.userId)?.firstName ===
        "Kiosk"
      )
    },
  },
}
</script>
