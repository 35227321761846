<template>
  <div>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="active" color="text" background-color="primary-darken4">
        <v-tab v-for="tab in tabs" :key="'tab-' + tab.name" :to="{ path: tab.name }">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="$route.params.tab" touchless>
        <v-tab-item
          v-for="tab in tabs"
          :key="'tab-item-' + tab.name"
          :value="tab.name"
          class="ma-4 mb-0"
        >
          <component :is="tab.component" v-if="$route.params.tab === tab.name" />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import TodaysOrders from "./Orders/TodaysOrders"
import PastOrders from "./Orders/PastOrders"
import ScheduledOrders from "./Orders/ScheduledOrders.vue"

export default {
  components: {
    TodaysOrders,
    PastOrders,
    ScheduledOrders,
  },
  data() {
    return {
      active: "orders",
    }
  },
  computed: {
    tabs() {
      const tabs = [
        { name: "orders", label: "Today's Orders", component: "todays-orders" },
        {
          name: "scheduled",
          label: "Scheduled Orders",
          component: "scheduled-orders",
        },
        { name: "history", label: "Past Orders", component: "past-orders" },
      ]
      return tabs
    },
  },
}
</script>
