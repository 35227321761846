<template>
  <orders-page
    title="Scheduled Orders"
    :show-date-filter="false"
    :include-completed-orders="false"
    scheduled-only
  />
</template>

<script>
import OrdersPage from "./index.vue"
export default {
  name: "todays-orders",
  components: {
    OrdersPage,
  },
}
</script>
