<template>
  <div v-if="order && $route.params.form == 'RefundForm'">
    <refund-process
      v-if="awaitingResponse || responseReceived"
      :awaitingResponse="awaitingResponse"
      :responseReceived="responseReceived"
      :refundId="refundId"
      :order="order"
      @close="close"
      @cancel="cancelDebitRefund"
      @back="cancelProcess"
    />
    <form-dialog
      v-else
      :visible="$route.params.form == 'RefundForm'"
      :editMode="false"
      :showFormTitle="false"
      :submitLabel="`Refund ` + formatMoneyAmount(finalRefundTotal, false)"
      :title="`Refund Order ` + order.orderNumber"
      :closeDisabled="awaitingResponse"
      :awaitingResponse="awaitingResponse"
      :submitDisabled="refundTotal <= 0"
      @close="close"
      @submit="submitHandle"
    >
      <v-row v-if="orderIsInterac">
        <v-col>
          <v-alert color="#ffb92b">
            <strong>
              <em>Interac</em>
              Refund
            </strong>
            <p class="ma-0 mb-1">
              Please note that after submitting, you will need to present the card to
              complete this refund.
            </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="2">
          <v-radio-group v-model="partialRefund">
            <v-radio :value="false" label="Full Refund" />
            <v-radio :value="true" label="Partial Refund" />
          </v-radio-group>
          <v-spacer />
        </v-col>
        <v-col cols="4">
          <div v-show="partialRefund" class="ml-2">
            <v-list>
              <v-list-item-title class="mt-3 mb-7 text--disabled">
                <h4>Select items to refund</h4>
              </v-list-item-title>
              <div v-for="restaurant in orderRestaurants" :key="restaurant.id">
                <v-list-item-subtitle class="mb-2">
                  <h3>{{ restaurant.name }}</h3>
                </v-list-item-subtitle>
                <v-list-item
                  v-for="orderItem in getOrderItemsForRestaurant(restaurant.id)"
                  :key="orderItem.index"
                >
                  <v-list-item-action class="align-self-start">
                    <v-checkbox
                      v-model="refundItemsIndeces"
                      :value="orderItem.index"
                      multiple
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ orderItem.quantity }}x {{ orderItem.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Subtotal:
                      {{
                        formatMoneyAmount(
                          orderItem.priceMoney.amount * orderItem.quantity,
                          false
                        )
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Modifiers price:
                      {{
                        formatMoneyAmount(
                          getOrderItemModifiersPrice(orderItem),
                          false
                        )
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Discount:
                      {{
                        formatMoneyAmount(-getOrderItemDiscount(orderItem), false)
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Taxes:
                      {{ formatMoneyAmount(getOrderItemTaxes(orderItem), false) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Total:
                      {{ formatMoneyAmount(getOrderItemTotal(orderItem), false) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action class="align-self-start">
                    <v-checkbox
                      v-model="refundTipRestaurantIds"
                      :value="restaurant.id"
                      :disabled="!getTipAmountForRestaurant(restaurant.id)"
                      multiple
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Tip</v-list-item-title>
                    <v-list-item-subtitle>
                      Total tip: {{ formatMoneyAmount(order.priceData.tip, false) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Share of tip:
                      {{
                        formatMoneyAmount(
                          getTipAmountForRestaurant(restaurant.id),
                          false
                        )
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-list-item-subtitle class="mb-2">
                <h3>{{ settings.organizationName }}</h3>
              </v-list-item-subtitle>
              <v-list-item>
                <v-list-item-action class="align-self-start">
                  <v-checkbox
                    v-model="refundOrganizationTip"
                    :disabled="!order.priceData.organizationTip"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Tip</v-list-item-title>
                  <v-list-item-subtitle>
                    Total tip: {{ formatMoneyAmount(order.priceData.tip, false) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Share of tip:
                    {{ formatMoneyAmount(order.priceData.organizationTip, false) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="align-self-start">
                  <v-checkbox
                    v-model="refundOrganizationDiscount"
                    :disabled="!order.priceData.organizationDiscount"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Discount</v-list-item-title>
                  <v-list-item-subtitle>
                    Total discount:
                    {{ formatMoneyAmount(order.priceData.discount, false) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Discount deducted:
                    {{
                      formatMoneyAmount(order.priceData.organizationDiscount, false)
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="align-self-start">
                  <v-checkbox v-model="refundPaymentProcessingFee" disabled />
                </v-list-item-action>
                <v-list-item-content>
                  <p class="ma-0 mb-1">
                    Remove the associated
                    {{ capitalize(paymentProvider) }}
                    Fee from {{ settings.organizationName }} and refund back to the
                    customer
                  </p>
                  <v-list-item-subtitle v-if="order.priceData.paymentProcessingFee">
                    Maximum
                    {{ capitalize(paymentProvider) }}
                    Fee: -{{
                      formatMoneyAmount(order.priceData.paymentProcessingFee, false)
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>$0.00</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="align-self-start">
                  <v-checkbox input-value="true" disabled />
                </v-list-item-action>
                <v-list-item-content>
                  <p class="ma-0 mb-1">Remove the associated commission</p>
                  <v-list-item-subtitle>
                    Associated commission:
                    {{ formatMoneyAmount(refundItemsOrganizationFee, false) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-spacer />
          </div>
        </v-col>
        <v-col cols="5">
          <div v-show="refundTotal != 0">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong class="text--disabled">Total Refundable:</strong>
                    {{ formatMoneyAmount(refundTotal, false) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template v-if="partialRefund">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      How would you like to settle this refund?
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-radio-group v-model="refundSettlement">
                      <v-radio value="auto" label="Automatic" />
                      <v-radio value="manual" label="Manual" />
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="(restaurantId, i) in refundRestaurantIds"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-row justify="start">
                      <v-col cols="6">
                        <v-list-item-title class="mt-5">
                          {{ $store.getters.getRestaurantName(restaurantId) }}
                        </v-list-item-title>
                        <span class="text--disabled">
                          Available funds:
                          {{
                            formatMoneyAmount(
                              getOrderRestaurant(restaurantId).netTotal,
                              false
                            )
                          }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="refundRestaurantIdsAmounts[i]"
                          prefix="$"
                          outlined
                          label="Amount to deduct"
                          :disabled="refundSettlement == 'auto'"
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-row justify="start">
                      <v-col cols="6">
                        <v-list-item-title class="my-5">
                          {{ settings.organizationName }}
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="refundOrganizationAmount"
                          prefix="$"
                          outlined
                          label="Amount to deduct"
                          :disabled="refundSettlement == 'auto'"
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item v-if="false">
                <v-list-item-content>
                  <v-checkbox
                    v-model="techDifficulties"
                    label="Is this refund due to technical difficulties?"
                  />
                  <v-checkbox
                    v-if="techDifficulties"
                    v-model="reviewRefund"
                    label="Would you like to submit this refund to NextGen Kitchens to pay?"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="orderIsInterac">
                <v-list-item-content>
                  <v-select
                    v-model="readerId"
                    :label="`${
                      isStripe ? 'Stripe Pinpad' : 'Square Terminal'
                    } to use for Refund`"
                    :items="availableReaders"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
      <v-row v-show="refundTotal != 0" justify="start">
        <v-col cols="12">
          <v-textarea
            v-model="note"
            outlined
            :label="reviewRefund ? `Note for NextGen Kitchens` : `Notes`"
          />
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import RefundProcess from "./RefundProcess.vue"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import GeneralMixins from "@/AuthenticatedContent/mixins"
import capitalize from "@/mixins/capitalize.js"
import OrdersMixins from "./mixins"
import { setDoc, doc, Timestamp } from "firebase/firestore"
import { mapState } from "vuex"

export default {
  name: "refund",
  components: {
    RefundProcess,
    FormDialog,
  },
  mixins: [OrdersMixins, GeneralMixins, capitalize],
  data() {
    return {
      awaitingResponse: false,
      responseReceived: false,
      refundOrder: null,
      refundId: null,
      partialRefund: false,
      refundItemsIndeces: [],
      refundTipRestaurantIds: [],
      refundRestaurantIdsAmounts: [],
      refundOrganizationTip: false,
      refundOrganizationDiscount: false,
      refundOrganizationAmount: 0,
      refundPaymentProcessingFee: true,
      refundServiceFee: false,
      readerId: null,
      availableReaders: [],
      note: "",
      refundSettlement: "auto",
      techDifficulties: false,
      reviewRefund: false,
      terminalRefundId: "",
    }
  },
  computed: {
    ...mapState(["restaurants", "firebaseRefs", "itemToEdit", "taxes", "settings"]),
    order() {
      return this.$route.params.form == "RefundForm" ? this.refundOrder : {}
    },
    orderIsInterac() {
      return this.order.channelData?.cardType?.toLowerCase() === "interac"
    },
    paymentProvider() {
      return this.settings.paymentProvider
    },
    isStripe() {
      return this.paymentProvider === "stripe"
    },
    orderRestaurants() {
      return [
        ...new Set(
          this.order.orderItems.map(item =>
            this.restaurants.find(restaurant => restaurant.id == item.restaurantId)
          )
        ),
      ]
    },
    refundItems() {
      if (!this.partialRefund && this.order) {
        return this.order.orderItems
      }
      return this.refundItemsIndeces.map(index => this.order.orderItems[index])
    },
    refundOrderItems() {
      return this.refundItems.map(item => {
        return {
          orderItemId: item.id,
          restaurantId: item.restaurantId,
          currency: item.priceMoney.currency,
          price: item.priceMoney.amount,
          quantity: item.quantity,
          modifiersPrice: this.getOrderItemModifiersPrice(item),
          discount: this.getOrderItemDiscount(item),
          subTotal: this.getOrderItemSubTotal(item),
          taxes: this.getOrderItemTaxes(item),
          total: this.getOrderItemTotal(item),
        }
      })
    },
    refundItemsOrganizationFee() {
      return Math.round(
        this.refundItems.reduce((acc, item) => {
          acc += parseFloat(this.getOrderItemOrganizationFee(item))
          return acc
        }, 0)
      )
    },
    refundRestaurantIds() {
      let restaurantIds = []
      this.refundItems.forEach(item => restaurantIds.push(item.restaurantId))
      return [...new Set(restaurantIds)]
    },
    refundTotal() {
      if (!this.partialRefund) {
        return this.order.priceData?.total
      }
      let refundTotal = 0
      if (this.refundItems) {
        refundTotal += this.refundItems.reduce((total, item) => {
          return total + (this.getOrderItemTotal(item) || 0)
        }, 0)
      }
      if (this.refundTipRestaurantIds) {
        refundTotal += this.refundTipRestaurantIds.reduce((total, restaurantId) => {
          return total + this.getTipAmountForRestaurant(restaurantId)
        }, 0)
      }
      if (this.refundOrganizationTip) {
        refundTotal += this.order.priceData?.organizationTip
      }
      if (this.refundOrganizationDiscount) {
        refundTotal += this.order.priceData?.organizationDiscount
      }
      return refundTotal
    },
    finalRefundTotal() {
      if (!this.partialRefund) {
        return this.refundTotal
      } else
        return Math.min(
          this.refundTotal,
          this.refundOrganizationAmount * 100 +
            this.refundRestaurantIdsAmounts.reduce((a, b) => a + b, 0) * 100
        )
    },
    // Return the total discount from all refunded items and the organization (if selected)
    finalRefundDiscount() {
      let total = 0
      if (this.refundItems) {
        this.refundItems.forEach(item => {
          if (!this.getOrderItemDiscount(item)) {
            // for excluding loyaltyReward items
            return
          }
          total += this.getOrderItemDiscount(item)
        })
      }
      if (this.refundOrganizationDiscount || !this.partialRefund) {
        total += this.order.priceData?.organizationDiscount ?? 0
      }
      return total
    },
    // Return the total tax from all refunded items
    finalRefundTaxes() {
      return this.refundItems.reduce((acc, item) => {
        if (this.getOrderItemTaxes(item)) {
          // for excluding loyaltyReward items
          acc += this.getOrderItemTaxes(item)
        }
        return acc
      }, 0)
    },
    // Return the sum of the subtotal from all refunded items
    finalRefundSubtotal() {
      return this.refundItems.reduce((acc, item) => {
        acc += this.getOrderItemSubTotalBeforeDiscount(item)
        return acc
      }, 0)
    },
    // Return the taxesList from all refunded items in the same format as the taxesList in the order
    // The returned array should have the taxes reduced by their id.
    finalRefundTaxesList() {
      let taxesList = []
      this.refundItems.forEach(item => {
        item.taxIds.forEach(taxId => {
          let tax = this.order?.priceData?.taxesList.find(t => t.id == taxId)
          if (tax) {
            let taxIndex = taxesList.findIndex(t => t.id == tax.id)
            if (taxIndex > -1) {
              taxesList[taxIndex].amount += tax.amount
            } else {
              taxesList.push({
                id: tax.id,
                name: tax.name,
                amount: tax.amount,
                type: tax.type,
              })
            }
          }
        })
      })
      return taxesList
    },
    // Return the total tip from all restaurants (if selected) and org tips (if selected)
    finalRefundTip() {
      let total = 0
      if (this.refundTipRestaurantIds) {
        this.refundTipRestaurantIds.forEach(restaurantId => {
          total += this.getTipAmountForRestaurant(restaurantId)
        })
      }
      if (this.refundOrganizationTip || !this.partialRefund) {
        total += this.order.priceData?.organizationTip ?? 0
      }
      return total
    },
  },
  watch: {
    refundItems() {
      this.updateRefundAmounts()
    },
    refundTipRestaurantIds() {
      this.updateRefundAmounts()
    },
    refundPaymentProcessingFee() {
      this.updateRefundAmounts()
    },
    refundOrganizationTip() {
      this.updateRefundAmounts()
    },
    refundSettlement() {
      this.updateRefundAmounts()
    },
    order() {
      this.updateRefundArrays()
    },
    partialRefund() {
      this.updateRefundArrays()
    },
  },
  async created() {
    this.refundOrder = await this.getOrderById(this.$route.params.id)
    await this.getSetReaders()
  },
  methods: {
    async getSetReaders() {
      let readers = []
      if (this.isStripe) {
        readers = (await this.getAvailableStripeReaders()) || []
      } else {
        readers =
          (await this.getAvailableSquareDevices(
            this.refundOrder.restaurants[0].id
          )) || []
      }

      if (readers?.length) {
        this.availableReaders = readers.map(r => {
          return {
            text: this.isStripe ? r.label : r.name,
            value: r.id,
            deviceId: r.deviceId || r.id,
          }
        })
        this.readerId = this.availableReaders[0].value
      }
    },
    async submitHandle() {
      this.awaitingResponse = true
      if (!this.editMode) {
        this.refundId = doc(this.firebaseRefs.refundsRef).id
      } else {
        this.refundId = this.itemToEdit.id
      }

      const refundDoc = {
        id: this.refundId,
        orderId: this.order.id,
        order: this.order,
        partialRefund: this.partialRefund,
        refundDate: Timestamp.fromDate(new Date()),
        refundTotal: this.finalRefundTotal,
        refundOrderItems: this.refundOrderItems,
        priceData: {
          currency: "CAD",
          discount: -this.finalRefundDiscount || 0,
          serviceCharge: -this.order?.priceData?.serviceCharge || 0,
          subTotal: -this.finalRefundSubtotal,
          taxesList: this.finalRefundTaxesList,
          bagFee: -this.order.priceData?.bagFee || 0,
          otherTax: -this.order.priceData?.otherTax || 0, // taxes other than GST and PST
          tip: -this.finalRefundTip || 0,
          total: -this.finalRefundTotal,
          totalTaxes: -this.finalRefundTaxes,
          paymentProcessingFeeRefund:
            -this.order.priceData?.paymentProcessingFee || 0,
        },
        deductionData: {
          organizationFee: this.formatMoneyAmount(this.refundItemsOrganizationFee),
          organizationTip: this.refundOrganizationTip
            ? this.formatMoneyAmount(this.order.priceData?.organizationTip)
            : 0,
          organizationDiscount: this.refundOrganizationDiscount
            ? this.formatMoneyAmount(this.order.priceData?.organizationDiscount)
            : 0,
          organizationTotal: this.formatMoneyAmount(
            this.refundOrganizationAmount * 100
          ),
          platformFee: this.formatMoneyAmount(this.order.priceData?.platformFee),
          deductServiceCharge: this.formatMoneyAmount(this.refundServiceFee),
          deductPaymentProcessingFee: this.formatMoneyAmount(
            this.refundPaymentProcessingFee
          ),
        },
        restaurants: this.refundRestaurantIds.map(restaurantId =>
          this.getFinalRefundRestaurantData(
            restaurantId,
            this.getOrderRestaurant(restaurantId)?.paymentProcessingFee ||
              this.getOrderRestaurant(restaurantId)?.stripeFee ||
              0
          )
        ),
        status: "Pending",
        note: this.note,
      }

      await setDoc(doc(this.firebaseRefs.refundsRef, this.refundId), refundDoc, {
        merge: true,
      }).catch(e => {
        console.error("Error with setting refund doc", e)
      })

      if (this.isStripe) {
        await this.processStripeRefund(this.refundId, this.readerId)
          .then(() => {
            this.responseReceived = true
          })
          .catch(e => {
            console.error("Error with processing Stripe refund", e)
          })
      } else {
        const deviceId = this.availableReaders.find(
          reader => reader.value === this.readerId
        )?.deviceId

        const terminalRefund = await this.processSquareRefund(
          this.order.orderItems[0].restaurantId,
          this.finalRefundTotal,
          this.orderIsInterac
            ? this.order.terminalPosOrderId
            : this.order.posOrderIds[0],
          this.order.id,
          this.note || "Refunded item",
          deviceId
        )
          .then(() => {
            this.responseReceived = true
          })
          .catch(e => {
            console.error("Error with processing Square refund", e)
          })

        this.terminalRefundId = terminalRefund?.id
      }
    },
    async cancelDebitRefund() {
      if (this.isStripe) {
        await this.cancelStripeTerminalPayment(this.readerId)
          .catch(e => {
            console.error("Error with cancelling Stripe terminal payment", e)
          })
          .finally(() => {
            this.awaitingResponse = false
            this.responseReceived = false
          })
      } else {
        await this.cancelSquareTerminalRefund(
          this.terminalRefundId,
          this.order.orderItems[0].restaurantId
        )
          .catch(e => {
            console.error("Error with cancelling Stripe terminal payment", e)
          })
          .finally(() => {
            this.awaitingResponse = false
            this.responseReceived = false
          })
      }
    },
    cancelProcess() {
      this.refundId = null
      this.awaitingResponse = false
      this.responseReceived = false
      this.terminalRefundId = ""
    },
    close() {
      this.$emit("update")
      this.$router.push({ params: { form: null, id: null } })
    },
    updateRefundArrays() {
      if (!this.partialRefund && this.order) {
        this.refundItemsIndeces = [...Array(this.order.orderItems.length).keys()]
        this.refundTipRestaurantIds = this.orderRestaurants.map(
          restaurant => restaurant.id
        )
      }
    },
    updateRefundAmounts() {
      if (this.refundSettlement == "auto") {
        this.refundRestaurantIdsAmounts = this.refundRestaurantIds.map(
          restaurantId =>
            Math.round(this.getTotalRefundableByRestaurant(restaurantId)) / 100
        )
        this.refundOrganizationAmount = this.getTotalRefundableByOrganization() / 100
      }
    },
    getOrderRestaurant(restaurantId) {
      return this.order.restaurants.find(restaurant => restaurant.id == restaurantId)
    },
    getTipAmountForRestaurant(restaurantId) {
      return parseFloat(this.getOrderRestaurant(restaurantId)?.tip) || 0
    },
    getDiscountAmountForRestaurant(restaurantId) {
      return parseFloat(this.getOrderRestaurant(restaurantId)?.discount) || 0
    },
    getTotalRefundableByRestaurant(restaurantId) {
      if (!this.refundItems) return 0
      let refundTotal = this.refundItems
        .filter(item => item.restaurantId == restaurantId)
        .reduce((acc, item) => {
          acc += this.getOrderItemTotal(item) || 0
          return acc
        }, 0)
      const thisRestaurantOrder = this.getOrderRestaurant(restaurantId)
      // calculate the percentage of the subtotal that is being refunded
      const refundPercentage =
        parseFloat(refundTotal) /
        ((thisRestaurantOrder?.subTotal || 0) +
          (thisRestaurantOrder.totalTaxes || 0))
      // subtract the proportional organization fee
      refundTotal -= thisRestaurantOrder?.organizationFee ?? 0 * refundPercentage
      if (this.refundTipRestaurantIds.includes(restaurantId)) {
        refundTotal += this.getTipAmountForRestaurant(restaurantId)
      }
      if (this.refundPaymentProcessingFee) {
        refundTotal -= thisRestaurantOrder?.paymentProcessingFee * refundPercentage
      }
      // the maximum this restaurant can refund is the total they received (net total)
      const maxRefundable = thisRestaurantOrder?.netTotal || 0
      return Math.min(refundTotal, maxRefundable)
    },
    getTotalRefundableByOrganization() {
      let refundTotal = 0
      if (this.refundOrganizationTip) {
        refundTotal += this.order.priceData?.organizationTip
      }
      if (this.refundOrganizationDiscount) {
        refundTotal += this.order.priceData?.organizationDiscount
      }
      const totalBeingRefundedByRestaurants = this.refundRestaurantIds
        .map(restaurantId =>
          parseFloat(this.getTotalRefundableByRestaurant(restaurantId))
        )
        .reduce((a, b) => a + b, 0)
      if (totalBeingRefundedByRestaurants + refundTotal < this.refundTotal) {
        return Math.round(
          this.refundTotal - totalBeingRefundedByRestaurants + refundTotal
        )
      }
      this.refundRestaurantIds.forEach(restaurantId => {
        let totalRefundableByRestaurant = 0
        this.refundItems
          .filter(item => item.restaurantId == restaurantId)
          .forEach(item => {
            totalRefundableByRestaurant += this.getOrderItemTotal(item)
          })
        const thisRestaurantOrder = this.getOrderRestaurant(restaurantId)
        const refundPercentage =
          parseFloat(totalRefundableByRestaurant) /
          ((thisRestaurantOrder?.subTotal || 0) +
            (thisRestaurantOrder.totalTaxes || 0))
        refundTotal += refundPercentage * thisRestaurantOrder?.organizationFee
      })
      return Math.round(refundTotal)
    },
    getOrderItemsForRestaurant(restaurantId) {
      return this.order.orderItems
        .map((orderItem, i) => {
          const orderItemNew = { ...orderItem }
          orderItemNew.index = i
          return orderItemNew
        })
        .filter(item => item.restaurantId == restaurantId)
    },
    getOrderItemOrganizationFee(item) {
      const restaurant = this.order.restaurants.find(
        restaurant => restaurant.id == item.restaurantId
      )
      if (!restaurant) return 0
      // // const orderItems = this.getOrderItemsForRestaurant(item.restaurantId)
      // // if (!orderItems.length) return 0
      // const totalForRestaurant = orderItems.reduce((acc, item) => {
      //   acc += item.priceMoney.amount
      //   return acc
      // }, 0)
      // if (!totalForRestaurant) return 0
      return parseFloat(
        (item.priceMoney.amount * (restaurant.organizationFee || 0)) /
          restaurant.subTotal
      )
    },
    getOrderItemModifiersPrice(item) {
      let modifiersPrice = 0
      if (item.modifiers?.length) {
        modifiersPrice += item.modifiers.reduce((acc, modifier) => {
          acc += modifier.priceMoney.amount
          return acc
        }, 0)
      }
      return modifiersPrice
    },
    getOrderItemDiscount(item) {
      const restaurantDiscount = this.getDiscountAmountForRestaurant(
        item.restaurantId
      )
      const allItemsFromThisRestaurantSubtotal = this.getOrderItemsForRestaurant(
        item.restaurantId
      ).reduce((acc, item) => {
        acc += item.priceMoney.amount
        return acc
      }, 0)
      const itemPercentage =
        item.priceMoney.amount / allItemsFromThisRestaurantSubtotal
      return restaurantDiscount * itemPercentage
    },
    getOrderItemSubTotal(item) {
      return (
        item.priceMoney.amount * item.quantity +
        this.getOrderItemModifiersPrice(item) -
        this.getOrderItemDiscount(item)
      )
    },
    getOrderItemSubTotalBeforeDiscount(item) {
      return (
        item.priceMoney.amount * item.quantity +
        this.getOrderItemModifiersPrice(item)
      )
    },
    getOrderItemTaxes(item) {
      let totalTaxPerc = 0
      let taxObj = {}
      if (item.taxIds.length > 0) {
        item.taxIds.forEach(tax => {
          taxObj = this.taxes.find(taxObj => taxObj.id == tax)
          totalTaxPerc += parseFloat(taxObj.amount)
        })
      }
      return this.roundTaxAmount(
        (totalTaxPerc / 100) * this.getOrderItemSubTotal(item)
      )
    },
    getOrderItemTotal(item) {
      return this.getOrderItemSubTotal(item) + this.getOrderItemTaxes(item)
    },
    getFinalRefundRestaurantData(restaurantId, paymentProcessingFee) {
      const isRestaurantRefunded = item => item.restaurantId === restaurantId
      const calculateTotal = item => parseFloat(item.total)
      const calculateSubTotal = item => parseFloat(item.subTotal)
      const calculateTaxes = item => parseFloat(item.taxes)
      const calculateDiscount = item => parseFloat(item.discount)
      const calculateTip = () =>
        this.refundTipRestaurantIds.includes(restaurantId)
          ? this.getTipAmountForRestaurant(restaurantId)
          : 0

      return {
        id: restaurantId,
        discount: -Math.abs(
          this.refundOrderItems
            .filter(isRestaurantRefunded)
            .reduce((acc, item) => acc + calculateDiscount(item), 0)
        ),
        grossTotal: -Math.abs(
          this.refundOrderItems
            .filter(isRestaurantRefunded)
            .reduce((acc, item) => acc + calculateTotal(item), 0) + calculateTip()
        ),
        netTotal: -Math.abs(
          this.refundOrderItems
            .filter(isRestaurantRefunded)
            .reduce((acc, item) => acc + calculateTotal(item), 0) +
            calculateTip() -
            paymentProcessingFee
        ),
        status: "Refunded",
        subTotal: -Math.abs(
          this.refundOrderItems
            .filter(isRestaurantRefunded)
            .reduce((acc, item) => acc + calculateSubTotal(item), 0)
        ),
        tip: -Math.abs(calculateTip()),
        totalTaxes: -Math.abs(
          this.refundOrderItems
            .filter(isRestaurantRefunded)
            .reduce((acc, item) => acc + calculateTaxes(item), 0)
        ),
      }
    },
    roundTaxAmount(num) {
      if (this.settings.taxRoundingMethod === "bankers") {
        var isNegative = num < 0
        var fixedNum = isNegative ? Math.ceil(num) : Math.floor(num)
        var fraction = num - fixedNum

        if (Math.abs(fraction) === 0.5) {
          return fixedNum + (fixedNum % 2 === 0 ? 0 : isNegative ? -1 : 1)
        } else {
          return Math.round(num)
        }
      } else {
        return Math.round(num)
      }
    },
  },
}
</script>
