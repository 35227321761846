import { functions } from "@/firebaseConfig"
import { getDoc, doc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings", "firebaseGetters"]),
  },
  methods: {
    async getOrderById(orderId) {
      const orderDoc = await getDoc(doc(this.firebaseGetters.ordersRef, orderId))
      return orderDoc.data()
    },
    async processStripeRefund(refundId, readerId) {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        refundId: refundId,
        readerId: readerId,
      }
      const response = await httpsCallable(
        functions,
        "processStripeRefund"
      )(requestObj)
      return response.status === 200
    },
    async processSquareRefund(
      restaurantId,
      total,
      posOrderId,
      orderId,
      reason,
      readerId
    ) {
      const requestObj = {
        restaurantId,
        orgId: this.firebaseRefs.organization.id,
        total,
        posOrderId,
        orderId,
        reason,
        deviceId: readerId,
      }
      const response = await httpsCallable(
        functions,
        "createSquareRefund"
      )(requestObj)
      return response.data
    },
    async cancelStripeTerminalPayment(readerId) {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        readerId: readerId,
      }
      const response = await httpsCallable(
        functions,
        "cancelStripeTerminalPayment"
      )(requestObj)
      return response.status === 200
    },
    async cancelSquareTerminalRefund(terminalRefundId, restaurantId) {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        terminalRefundId,
        restaurantId,
      }
      const response = await httpsCallable(
        functions,
        "cancelSquareTerminalRefund"
      )(requestObj)
      return response.data !== null
    },
    async getAvailableStripeReaders() {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        stripeLocationId: this.settings.stripeLocationId,
      }

      return (await httpsCallable(functions, "stripeReaderList")(requestObj))?.data
        ?.data
    },
    async getAvailableSquareDevices(restaurantId) {
      const requestObj = {
        restaurantId,
        orgId: this.firebaseRefs.organization.id,
      }

      const response = await httpsCallable(
        functions,
        "squareDevicesList"
      )(requestObj)
      return response.data
    },
    async markOrdersAsCompleted(orderIdsArray) {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        orderIdsArray: orderIdsArray,
      }
      const response = await httpsCallable(
        functions,
        "markOrdersAsCompleted"
      )(requestObj)
      return response
    },
  },
}
