<template>
  <v-menu open-on-click allow-overflow content-class="chart-menu">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="ml-2"
        v-bind="attrs"
        small
        v-on="on"
        @click.stop="prepareChart(order)"
      >
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-center mb-n15">Order Timing</v-card-title>
      <v-card-text
        ref="timing-chart"
        class="chart-container pa-0 mr-0"
        style="min-height: 100px !important"
      >
        <vue-apex-charts
          type="rangeBar"
          :options="chartOptions"
          :series="series"
        ></vue-apex-charts>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import moment from "moment"

export default {
  name: "order-detail-source",
  components: {
    VueApexCharts,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 50,
          type: "rangeBar",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "10%",
            rangeBarGroupRows: true,
          },
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D4526E",
          "#8D5B4C",
          "#F86624",
          "#D7263D",
          "#1B998B",
          "#2E294E",
          "#F46036",
          "#E2C044",
        ],
        fill: {
          type: "solid",
        },
        xaxis: {
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          custom: function (opts) {
            const w = opts.ctx.w
            let ylabel = w.globals.labels[opts.dataPointIndex]
            let seriesName = w.config.series[opts.seriesIndex].name
              ? w.config.series[opts.seriesIndex].name
              : ""
            const color = w.globals.colors[opts.seriesIndex]

            const fromTime = moment(
              w.config.series[opts.seriesIndex].data[0].y[0]
            ).format("h:mm:ss A")
            const toTime = moment(
              w.config.series[opts.seriesIndex].data[0].y[1]
            ).format("h:mm:ss A")
            const duration =
              w.config.series[opts.seriesIndex].data[0].y[1] -
              w.config.series[opts.seriesIndex].data[0].y[0]

            return (
              '<div class="apexcharts-tooltip-rangebar">' +
              '<div> <span class="series-name" style="color: ' +
              color +
              '">' +
              (seriesName ? seriesName.replace("Until", "<br/>Until") : "") +
              "</span></div>" +
              '<div> <span class="category">Duration:</span> <span class="value">' +
              duration / 1000 +
              ' s </span><br/><span class="category">' +
              ylabel +
              ':</span> <span class="value start-value">' +
              fromTime +
              '</span> <span class="separator">-</span> <span class="value end-value">' +
              toTime +
              "</span></div>" +
              "</div>"
            )
          },
        },
      }
    },
  },
  methods: {
    prepareChart(order) {
      this.series = this.getSeries(order)
      this.$nextTick(() => {
        this.setMinHeight()
      })
    },
    setMinHeight() {
      this.$refs["timing-chart"].style.minHeight = "100px"
    },
    getSeries(order) {
      const series = []
      let timestampData = { ...order.timestampData }
      if (Object.keys(timestampData).length) {
        const timestampsOrdered = Object.keys(timestampData)
          .filter(key => key !== "OrderCreated")
          .map(key => {
            return {
              name: key,
              date: new Date(timestampData[key]).getTime(),
            }
          })
          .sort((a, b) => a.date - b.date)

        // create a series range for each pair of sequential timestamps
        for (let i = 0; i < timestampsOrdered.length - 1; i++) {
          series.push({
            name: `From ${timestampsOrdered[i].name} Until ${
              timestampsOrdered[i + 1].name
            }`,
            data: [
              {
                x: "Time",
                y: [timestampsOrdered[i].date, timestampsOrdered[i + 1].date],
              },
            ],
          })
        }
      }
      return series
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-container {
  min-height: 100px !important;
}
.chart-menu {
  overflow: visible !important;
  contain: none;
}
</style>
