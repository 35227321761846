<template>
  <v-row no-gutters>
    <v-col cols="12" lg="8">
      <v-card
        class="mt-4 mb-n2 pl-1 summary-card"
        color="light-background"
        outlined
        shaped
      >
        <v-card-title class="pb-0 text-body-1 text-uppercase">
          Totals Summary
        </v-card-title>
        <v-card-subtitle class="lighttext--text pt-4 pb-0">
          <span v-if="todayOnly">Includes all paid orders</span>
          <span v-else>Sum of all filtered orders</span>
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-row align="center" no-gutters>
            <v-col cols="12" sm="4">
              <summary-stat-panel title="Sales" :stat="'CA$' + totalSales" />
            </v-col>
            <v-col cols="12" sm="4">
              <summary-stat-panel title="Orders" :stat="orderCount + ''" />
            </v-col>
            <v-col cols="12" sm="4">
              <summary-stat-panel
                title="Average Ticket"
                :stat="'CA$' + avgTicketSize"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SummaryStatPanel from "@/AuthenticatedContent/shared/SummaryStatPanel.vue"

export default {
  name: "orders-summary",
  components: {
    SummaryStatPanel,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    todayOnly: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    totalSales() {
      let totalSales = this.orders.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.priceData.total
      }, 0)
      return Math.round(parseFloat(totalSales)) / 100
    },
    orderCount() {
      return this.orders.length
    },
    avgTicketSize() {
      const totalSales = this.totalSales
      const orderCount = this.orderCount

      return (
        Math.round(parseFloat(orderCount > 0 ? totalSales / orderCount : 0) * 100) /
        100
      )
    },
  },
}
</script>
